import { createSlice } from '@reduxjs/toolkit';

import { keyBy } from 'lodash';

import { fetchGroup } from './thunks';
import { IGroupState, IGroupApps } from './types';

export const initialState = {
  loading: false,
} as IGroupState;

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGroup.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchGroup.fulfilled, function (state, action) {
        const { apps, group } = action.payload;

        const applications = keyBy(apps, 'key') as IGroupApps;

        return {
          ...state,
          ...group,
          applications,
          loading: false,
        };
      });
  },
});
