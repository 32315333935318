import { getGroupUrlSegments, Tab } from 'common/controllers';

import * as media from 'store/media';
import { GroupAppKey, isAppAvailable } from 'store/group';

import { EWidgetController, ICreateController } from '../types';

import { IMediaControllerProps } from './types';

export const createController: ICreateController = async (
  controllerParams,
  store,
  stale = false,
) => {
  const { flowAPI } = controllerParams;
  const { wixCodeApi, setProps } = flowAPI.controllerConfig;

  return {
    name: EWidgetController.media,

    async pageReady() {
      exposeMethods();

      if (!stale) {
        await resolve();
      }

      wixCodeApi.location.onChange(resolve);
    },
  };

  async function resolve() {
    if (!(await guard())) {
      return;
    }

    const state = store.getState();
    await store.dispatch(media.thunks.fetch(state.group.id!));
  }

  // TODO should be a part of wix-router
  async function guard() {
    const isAllowed = isAppAvailable(store.getState(), GroupAppKey.GALLERY_APP);

    const { activeTab } = await getGroupUrlSegments(
      wixCodeApi.location,
      wixCodeApi.site,
      wixCodeApi.window.viewMode,
    );

    return isAllowed && activeTab === Tab.MEDIA;
  }

  function exposeMethods() {
    setProps({
      media$: {},
    } as IMediaControllerProps);
  }
};
