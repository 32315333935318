import { ControllerParams } from '@wix/yoshi-flow-editor';
import { IWidgetController as WidgetController } from '@wix/native-components-infra/dist/src/types/types';

import { IRootStore, IRootState } from 'Group/store';

import { IGroupControllerProps } from './group/types';
import { IMediaControllerProps } from './media/types';

export enum EWidgetController {
  group = 'group$',
  media = 'media$',
}

/**
 * Root props type
 */
export type IControllerProps = IGroupControllerProps &
  IMediaControllerProps & { store: IRootState };

export interface IWidgetController extends WidgetController {
  name: EWidgetController;
}

/**
 * Create Sub controller type
 */
export type ICreateController = (
  controllerContext: ControllerParams,
  store: IRootStore,
  stale: boolean,
) => Promise<IWidgetController> | IWidgetController;

export type IWidgetControllers = {
  [key in EWidgetController]: IWidgetController;
};
