import { ISocialContentService } from '@wix/social-groups-api';

import * as IFeedTypes from '@wix/ambassador-feed-v1-feed-item/types';
import * as IReactionsTypes from '@wix/ambassador-reactions-v1-identity-reaction/types';

import { action, comparer, computed, flow, observable } from 'mobx';
import { list, object, raw, serializable } from 'serializr';

import { FeedItem } from './FeedItem';
import { ITopic } from 'Group/types/ITopic';
import { IFeedItemEntity, IFeedStore } from 'Group/types/IFeedItem';
import { PaginationState } from '@wix/comments-ooi-client/controller';
import { IHttpClient } from '@wix/yoshi-flow-editor';

import * as feedApi from 'api/feed';

const FETCH_FEED_FIELDSET = 'latestComments,reactions,requesterContext';

export const DEFAULT_FEED_LIMIT = 10;

export class FeedStore implements IFeedStore {
  @serializable(list(raw()))
  @observable.shallow
  topics: ITopic[] = [];

  @observable
  private feedItemsMap: Map<string, FeedItem> = new Map<string, FeedItem>();

  @serializable @observable loading: boolean = false;

  @serializable @observable cursor: string | null = null;
  @serializable @observable nextCursor: string | null = null;
  @serializable @observable prevCursor: string | null = null;

  public repository: ISocialContentService;

  constructor(
    repository: ISocialContentService,
    private groupId: string,
    private httpClient: IHttpClient,
  ) {
    this.repository = repository;
  }

  @serializable(list(object(FeedItem)))
  @computed({ equals: comparer.shallow })
  get feedItems() {
    return Array.from(this.feedItemsMap.values());
  }

  @action
  onReactedOnFeedItem(item: any) {
    const feedItem = this.feedItemsMap.get(item.feedItemId);

    // maybe not loaded yet
    if (!feedItem) {
      return;
    }

    const userReaction: IReactionsTypes.IdentityReaction = {
      identity: {
        identityId: item.createdBy!.identityId,
      },
      reaction: item.reaction,
    };

    // probably same tab, so no action needed
    if (feedItem.reactions.has(userReaction)) {
      return;
    }

    feedItem.reactions.add(userReaction);
  }

  @action fetchTopics = flow(function* (this: FeedStore) {
    try {
      const [topicsStats, { topics }] = yield Promise.all([
        this.repository.feed.topicsStats({}),
        this.repository.topics.list({
          paging: {
            limit: 100,
          },
        }),
      ]);

      const stats = topicsStats.topicCounts;

      this.topics = topics
        .map((topic: any) => ({
          ...topic,
          count: stats[topic.id] || 0,
        }))
        .sort((a: any, b: any) => (b.count > a.count ? 1 : -1));
    } catch (error) {
      console.error(`FeedStore fetchTopics`, error);
    }
  });

  @action createTopic = flow(function* (this: FeedStore, displayName: string) {
    const topic = yield this.repository.feed.createTopic({ displayName });

    this.topics.push({
      id: topic.id,
      displayName,
      createdDate: new Date(),
      count: 0,
    });
  });

  @action fetch = flow(function* (this: FeedStore, cursor?: string) {
    this.feedItemsMap = new Map();

    this.loading = true;

    try {
      yield this.fetchFeedItems(cursor);
    } finally {
      this.loading = false;
    }
  });

  @action fetchMore = flow(function* (this: FeedStore) {
    if (!this.nextCursor) {
      return;
    }

    yield this.fetchFeedItems(this.nextCursor);
  });

  private async fetchFeedItems(
    cursor?: string,
    limit: number = DEFAULT_FEED_LIMIT,
  ) {
    const { data: response } = await this.httpClient.request(
      feedApi.fetch(this.groupId, {
        fieldset: FETCH_FEED_FIELDSET,
        cursor: {
          limit,
          cursor,
        },
      }),
    );

    const feedItems = response.items.map(
      (feedItem) => new FeedItem(feedItem, this.groupId, this.httpClient),
    );

    this.addFeedItems(feedItems);

    this.cursor = cursor || null;
    this.nextCursor = response.nextCursor;
    this.prevCursor = response.prevCursor;
  }

  @action getFeedItem = flow(function* (this: FeedStore, feedItemId: string) {
    this.loading = true;
    try {
      const { data } = yield this.httpClient.request(
        feedApi.get(this.groupId, feedItemId),
      );

      this.cursor = null as any;
      this.feedItemsMap = new Map();

      const feedItem = new FeedItem(data.item, this.groupId, this.httpClient);

      this.addFeedItems([feedItem]);
    } catch (error) {
      console.error(`FeedStore getFeedItem`, feedItemId, error);
    } finally {
      this.loading = false;
    }
  });

  @action filter = flow(function* (this: FeedStore, filter) {
    this.loading = true;
    try {
      const { data: response } = yield this.httpClient.request(
        feedApi.search(this.groupId, {
          filter,
        }),
      );

      this.cursor = null as any;
      this.feedItemsMap = new Map();

      this.addFeedItems(
        response.items.map(
          (feedItem: any) =>
            new FeedItem(feedItem, this.groupId, this.httpClient),
        ),
      );
    } catch (error) {
      console.error(`FeedStore filter`, filter, error);
    } finally {
      this.loading = false;
    }
  });

  @action
  updateTopicCounter(topicId?: string, count: number = 0) {
    if (!topicId) {
      return;
    }

    const topic = this.topics.find((topicEl) => topicEl.id === topicId);

    if (!topic) {
      return;
    }

    topic.count! += count;
  }

  @action create = flow(function* (
    this: FeedStore,
    entity: IFeedTypes.FeedItemEntity,
  ) {
    const { data: response } = yield this.httpClient.request(
      feedApi.create(this.groupId, {
        entity: entity as any,
      }),
    );

    const feedItem = new FeedItem(response, this.groupId, this.httpClient);
    const topic = feedItem.entity.topics[0];
    this.feedItemsMap.set(feedItem.feedItemId, feedItem);
    this.feedItems.unshift(feedItem);
    this.updateTopicCounter(topic.id, +1);
  });

  @action update = flow(function* (
    this: FeedStore,
    feedItemId: string,
    entity: IFeedTypes.FeedItemEntity,
  ) {
    yield this.updateFeedItem(feedItemId, entity);
  });

  private async updateFeedItem(
    feedItemId: string,
    entity: IFeedTypes.FeedItemEntity,
  ) {
    const { data: updated } = await this.httpClient.request(
      feedApi.update(this.groupId, {
        feedItemId,
        entity: entity as any,
      }),
    );

    const feedItem = this.feedItemsMap.get(updated.feedItemId!);
    const topic = feedItem!.entity.topics[0];
    this.updateTopicCounter(topic.id, -1);
    feedItem!.setEntity(updated.entity as IFeedItemEntity, updated.updatedAt!);
    this.updateTopicCounter(updated.entity.topics[0]?.id, +1);
  }

  @action delete = flow(function* (this: FeedStore, feedItemId: string) {
    if (this.feedItemsMap.has(feedItemId)) {
      const feedItem = this.feedItemsMap.get(feedItemId);
      const topic = feedItem!.entity.topics[0];
      this.feedItemsMap.delete(feedItemId);

      yield this.httpClient.request(feedApi.remove(this.groupId, feedItemId));

      // Activity post doesn't have entity
      if (feedItem!.entity && feedItem!.entity.topics) {
        this.updateTopicCounter(topic.id, -1);
      }
    }
  });

  @action pin = flow(function* (this: FeedStore, feedItemId: string) {
    try {
      const pinnedFeedItem = this.feedItems.find((feedItem) => feedItem.pin);
      if (pinnedFeedItem) {
        yield this.httpClient.request(
          feedApi.unpin(this.groupId, pinnedFeedItem.feedItemId),
        );
      }

      yield this.httpClient.request(feedApi.pin(this.groupId, feedItemId));

      yield this.fetch();
    } catch (error) {
      console.error('FeedStore FeedItem pin error: ', error);
    }
  });

  @action unpin = flow(function* (this: FeedStore, feedItemId: string) {
    try {
      yield this.httpClient.request(feedApi.unpin(this.groupId, feedItemId));
      yield this.fetch();
    } catch (error) {
      console.error('FeedStore FeedItem unpin error: ', error);
    }
  });

  @action follow = flow(function* (this: FeedStore, feedItemId: string) {
    try {
      yield this.httpClient.request(
        feedApi.subscribe(this.groupId, feedItemId),
      );

      const feedItem = this.feedItemsMap.get(feedItemId);

      if (feedItem) {
        feedItem.requesterContext.subscribed = true;
      }
    } catch (error) {
      console.error('FeedStore FeedItem follow error: ', error);
    }
  });

  @action unfollow = flow(function* (this: FeedStore, feedItemId: string) {
    try {
      yield this.httpClient.request(
        feedApi.unsubscribe(this.groupId, feedItemId),
      );

      const feedItem = this.feedItemsMap.get(feedItemId);

      if (feedItem) {
        feedItem.requesterContext.subscribed = false;
      }
    } catch (error) {
      console.error('FeedStore FeedItem unfollow error: ', error);
    }
  });

  /**
   * @deprecated
   * use addReaction
   * */
  @action react(feedItemId: string, reaction: IReactionsTypes.Reaction) {
    return this.feedItems
      .find((feedItem) => feedItem.feedItemId === feedItemId)!
      .react(reaction);
  }

  /**
   * @deprecated
   * use removeReaction
   * */
  @action unreact(feedItemId: string, reactionCode: string) {
    return this.feedItems
      .find((feedItem) => feedItem.feedItemId === feedItemId)!
      .unreact(reactionCode);
  }

  @action addReaction(
    this: FeedStore,
    feedItemId: string,
    userReaction: IReactionsTypes.IdentityReaction,
  ) {
    return this.feedItems
      .find((feedItem) => feedItem.feedItemId === feedItemId)!
      .addUserReaction(userReaction);
  }

  @action removeReaction(
    feedItemId: string,
    userReaction: IReactionsTypes.IdentityReaction,
  ) {
    return this.feedItems
      .find((feedItem) => feedItem.feedItemId === feedItemId)!
      .removeUserReaction(userReaction);
  }

  updateComments(paginationState: PaginationState) {
    for (const [resourceId, resourcePaginationState] of Object.entries(
      paginationState,
    )) {
      const feedItem = this.feedItemsMap.get(resourceId);
      if (feedItem && resourcePaginationState.type === 'READY') {
        feedItem.setCommentsTotal(
          resourcePaginationState.totals.topLevelComments,
        );
      }
    }
  }

  private addFeedItems(feedItems: FeedItem[]) {
    for (const feedItem of feedItems) {
      if (!feedItem.activity || feedItem.hasValidActivity()) {
        this.feedItemsMap.set(feedItem.feedItemId, feedItem);
      }
    }
  }
}
