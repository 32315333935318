import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { login } from './thunks';
import { IApplicationState, IApplicationUser } from './types';

export const initialState: IApplicationState = {
  user: {} as IApplicationUser,
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});
