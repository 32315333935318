import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import * as application from 'store/application';

import { createStore } from 'Group/store';
import { WARMUP_DATA_KEY } from 'Group/constants';

import { createController as group$ } from './group';
import { createController as media$ } from './media';

import { IWidgetControllers } from './types';

export const createController: CreateControllerFn = async (params) => {
  const { flowAPI } = params;
  const { wixCodeApi, setProps } = flowAPI.controllerConfig;

  const { warmupData } = wixCodeApi.window;
  const { isSSR } = flowAPI.environment;

  const store = createStore(params);

  const stale = isSSR && warmupData.get(WARMUP_DATA_KEY);

  const controllers = await Promise.all([
    group$(params, store, stale),
    media$(params, store, stale),
  ]).then((controllers) =>
    controllers.reduce((acc, controller) => {
      acc[controller.name] = controller;
      return acc;
    }, {} as IWidgetControllers),
  );

  return {
    async pageReady() {
      await store.dispatch(
        application.thunks.login(wixCodeApi.user.currentUser),
      );

      // Like "parent" controller, sub-controllers are dependent on store's group state
      // Later wix-router will resolve route controllers by itself
      await controllers.group$.pageReady();

      // Invoke sub-controllers, ensures group state is ready
      await Promise.all([controllers.media$.pageReady()]);

      // Persist warmup data after SSR
      if (isSSR) {
        warmupData.set(WARMUP_DATA_KEY, store.getState());
      }

      exposeStore();

      store.subscribe(exposeStore);
      wixCodeApi.user.onLogin(handleUserLogin);
    },
  };

  function exposeStore() {
    setProps({
      store: store.getState(),
    });
  }

  function handleUserLogin() {
    store.dispatch(application.thunks.login(wixCodeApi.user.currentUser));
  }
};
