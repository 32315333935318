// TODO get rid of this somehow
import { getGroupUrlSegments } from 'common/controllers';

import * as group from 'store/group';

import { EWidgetController, ICreateController } from '../types';

import { IGroupControllerProps } from './types';

export const createController: ICreateController = async (
  controllerParams,
  store,
  stale = false,
) => {
  const { flowAPI, controllerConfig } = controllerParams;

  console.log('createController', {
    controllerConfigLocation: controllerConfig.wixCodeApi.location,
    flowAPILocation: flowAPI.controllerConfig.wixCodeApi.location,
  });

  const { groupId } = await getGroupUrlSegments(
    flowAPI.controllerConfig.wixCodeApi.location,
    flowAPI.controllerConfig.wixCodeApi.site,
    flowAPI.controllerConfig.wixCodeApi.window.viewMode,
  );

  console.log('createController::groupId:', groupId);

  return {
    name: EWidgetController.group,

    async pageReady() {
      if (!stale) {
        await store.dispatch(group.thunks.fetchGroup(groupId));
      }

      exposeMethods();
    },
  };

  function exposeMethods() {
    flowAPI.controllerConfig.setProps({
      group$: {
        // sub-controller methods
        // usually a pure function wrapper of dispatch(action)
      },
    } as IGroupControllerProps);
  }
};
