import { createAsyncThunk } from '@reduxjs/toolkit';

import { FlowAPI } from '@wix/yoshi-flow-editor';

import * as media from 'api/media';

export const fetch = createAsyncThunk(
  'media:fetch',
  async function (groupId: string, thunkAPI) {
    const flowApi = thunkAPI.extra as FlowAPI;

    try {
      const response = await flowApi.httpClient.request(media.fetch(groupId));

      return response.data;
    } catch (error) {
      flowApi.errorMonitor.captureException(error as Error);
      return Promise.reject(error);
    }
  },
);
