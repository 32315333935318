import { createAsyncThunk } from '@reduxjs/toolkit';

import { FlowAPI } from '@wix/yoshi-flow-editor';

import * as groups from 'api/groups';
import { listApps } from '@wix/ambassador-social-groups-v1-group-app/http';

export const fetchGroup = createAsyncThunk(
  'group:fetch',
  async function (groupId: string, thunkAPI) {
    const flowApi = thunkAPI.extra as FlowAPI;

    try {
      const response = await flowApi.httpClient.request(
        groups.getGroup(groupId),
      );

      const { group } = response.data;

      if (!group) {
        throw new Error('Group not found');
      }

      const { data } = await flowApi.httpClient.request(
        listApps({
          groupId: group.id as string,
        }),
      );

      return {
        group,
        apps: data.apps || [],
      };
    } catch (error) {
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);
