import { createSelector } from 'reselect';
import { filter, sortBy } from 'lodash';

import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';
import { MembershipStatus } from '@wix/ambassador-social-groups-v2-group-member/types';

import { GroupAppKey, IPartialStore, GroupApp } from './types';

export const installedApplications = createSelector(
  (state: IPartialStore) => state.group.applications,
  (applications) => {
    return sortBy(
      filter<GroupApp>(Object.values(applications || {}), 'installed'),
      'tabOrderIndex',
    );
  },
);

export const isAppAvailable = createSelector(
  [
    (state: IPartialStore) => state.group,
    (state: IPartialStore, application: GroupAppKey) =>
      state.group.applications?.[application],
  ],
  (group, application) => {
    if (!group.id || !application) {
      return false;
    }

    const isInstalled = application.installed!;
    const isJoined = group.membership === MembershipStatus.JOINED;

    if (application.key === GroupAppKey.ABOUT_APP) {
      return isInstalled;
    }

    switch (group.privacyStatus) {
      case PrivacyStatus.PRIVATE:
      case PrivacyStatus.SECRET:
        return isJoined && isInstalled;

      case PrivacyStatus.PUBLIC:
      default:
        return isInstalled;
    }
  },
);
