import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit, pick } from 'lodash';
import { FlowAPI, IUser } from '@wix/yoshi-flow-editor';

import * as members from 'api/members';

import { IApplicationUser } from './types';

export const login = createAsyncThunk<IApplicationUser, IUser>(
  'application:login',
  async function (wixUser: IUser, thunkAPI) {
    const flowApi = thunkAPI.extra as FlowAPI;
    const user = pick(wixUser, 'loggedIn', 'role', 'instance');

    if (!wixUser.loggedIn) {
      return {
        siteMemberId: wixUser.id,
        ...user,
      };
    }

    try {
      const response = await flowApi.httpClient.request(
        members.getMember(wixUser.id),
      );

      const member = response.data;

      if (!member) {
        throw new Error('Member not found');
      }

      return {
        ...user,
        ...member,
      };
    } catch (error) {
      flowApi.errorMonitor.captureException(error as Error);
      return Promise.reject(error);
    }
  },
);
